export default {
    loading: {
        full: false,
        page: false,
        save: false,
        marcas: false,
        modelos: false,
        anos: false,
        fipe: false,
        estados: false,
        implementos: false,
    },

    cotacao: {
        veiculo_tipo_id: null,
        pesado: false,
        marca_id: null,
        modelo_id: null,
        ano_id: null,
        fipe: null,
        valor_fipe: null,
        implemento_id: null,
        valor_implemento: null,
        nome: null,
        email: null,
        telefone: null,
        estado_id: null,
        blacklist: false,
        plano_estado_id: null,
        cilindrada: null,
    },
    planos: [],
    
    simulacao: {
        implemento: false,
    },

    erros: {
        marca_id: [],
        modelo_id: [],
        ano_id: [],
        fipe: [],
        valor_fipe: [],

        implemento_id: [],
        valor_implemento: [],

        nome: [],
        email: [],
        senha: [],
        login: [],
        telefone: [],
        estado_id: [],
        error_blacklist: [],
        cilindrada: [],
    },

    marcas: [],
    modelos: [],
    anos: [],
    estados: [],
    cilindradas: [
        { value: 150, label: "0 à 150" },
        { value: 160, label: "151 à 160" },
        { value: 250, label: "161 à 250" },
        { value: 400, label: "251 à 400" },
        { value: 500, label: "401 à 500" },
        { value: 750, label: "501 à 750" },
        { value: 900, label: "751 à 900" },
        { value: 1100, label: "901 à 1100" },
    ],
    implementos: [],
    adicionais: [],

    fipe: {},
    auth: {
        logged: false,
        usuario: {},
    },
    usuario: {},

    plano_estados: [],

    data_table: {
        showModal: false,
        item: {},
    },
};
