import Vue from "vue";

export default {
    SET_LOADING_FULL: (state, payload) => (state.loading.full = payload),
    SET_LOADING_PAGE: (state, payload) => (state.loading.page = payload),
    SET_LOADING_SAVE: (state, payload) => (state.loading.save = payload),
    SET_LOADING_MARCAS: (state, payload) => (state.loading.marcas = payload),
    SET_LOADING_MODELOS: (state, payload) => (state.loading.modelos = payload),
    SET_LOADING_ANOS: (state, payload) => (state.loading.anos = payload),
    SET_LOADING_FIPE: (state, payload) => (state.loading.fipe = payload),
    SET_LOADING_ESTADOS: (state, payload) => (state.loading.estados = payload),
    SET_LOADING_IMPLEMENTOS: (state, payload) => (state.loading.implementos = payload),

    SET_COTACAO: (state, payload) => (state.cotacao = payload),
    SET_COTACAO_ID: (state, payload) => (state.cotacao.id = payload),
    SET_COTACAO_CLIENTE_ID: (state, payload) => (state.cotacao.cliente_id = payload),

    SET_COTACAO_TIPO(state, payload) {
        state.cotacao.veiculo_tipo_id = payload.label;
        if (payload.flag) {
            localStorage.clear();
            state.cotacao.pesado = false;
            state.cotacao.marca_id = null;
            state.cotacao.modelo_id = null;
            state.cotacao.ano_id = null;
            state.cotacao.fipe = null;
            state.cotacao.valor_veiculo = null;
            state.cotacao.implemento_id = null;
            state.cotacao.valor_implemento = null;
            state.simulacao.implemento = false;
        }
    },
    SET_COTACAO_PESADO: (state, payload) => (state.cotacao.pesado = payload),

    SET_COTACAO_MARCA_ID(state, payload) {
        state.cotacao.marca_id = payload;
        state.erros.marca_id = [];
        state.cotacao.modelo_id = null;
        state.cotacao.ano_id = null;
        state.cotacao.fipe = null;
        state.cotacao.valor_veiculo = null;
    },
    SET_COTACAO_MARCA(state, payload) {
        state.cotacao.marca = payload;
    },
    SET_COTACAO_MODELO_ID(state, payload) {
        state.cotacao.modelo_id = payload;
        state.erros.modelo_id = [];
        state.cotacao.ano_id = null;
        state.cotacao.fipe = null;
        state.cotacao.valor_veiculo = null;
    },
    SET_COTACAO_MODELO(state, payload) {
        state.cotacao.modelo = payload;
    },
    SET_COTACAO_COMBUSTIVEL(state, payload) {
        state.cotacao.combustivel = payload;
    },
    SET_COTACAO_ANO(state, payload) {
        state.cotacao.ano_id = payload;
        state.erros.ano_id = [];
        state.cotacao.fipe = null;
        state.cotacao.valor_veiculo = null;
    },
    SET_COTACAO_FIPE(state, payload) {
        state.cotacao.fipe = payload;
    },
    SET_COTACAO_VALOR_FIPE(state, payload) {
        state.cotacao.valor_fipe = payload;
    },
    SET_IMPLEMENTO_FIXO(state, payload) {
        state.simulacao.implemento = payload;
        if (!payload) {
            state.cotacao.implemento_id = null;
            state.cotacao.valor_implemento = null;
        }
    },
    SET_COTACAO_IMPLEMENTO(state, payload) {
        state.cotacao.implemento_id = payload;
    },
    SET_COTACAO_VALOR_IMPLEMENTO(state, payload) {
        state.cotacao.valor_implemento = payload;
    },
    SET_COTACAO_ESTADO(state, payload) {
        state.cotacao.estado_id = payload;
        state.erros.estado_id = [];
    },
    SET_COTACAO_NOME(state, payload) {
        state.cotacao.nome = payload;
    },
    SET_COTACAO_EMAIL(state, payload) {
        state.cotacao.email = payload;
    },
    SET_COTACAO_TELEFONE(state, payload) {
        state.cotacao.telefone = payload;
    },

    SET_ERROS(state, payload) {
        Object.keys(state.erros).forEach(key => {
            let value = [];
            if (key in payload) {
                value = payload[key];
            }
            Vue.set(state.erros, key, value);
        });
    },

    SET_MARCAS(state, payload) {
        state.marcas = payload;
    },
    SET_MODELOS(state, payload) {
        state.modelos = payload;
    },
    SET_ANOS(state, payload) {
        state.anos = payload;
    },
    SET_ESTADOS(state, payload) {
        state.estados = payload;
    },
    SET_FIPE(state, payload) {
        state.fipe = payload;
    },
    SET_IMPLEMENTOS(state, payload) {
        state.implementos = payload;
    },
    SET_ADICIONAIS(state, payload) {
        state.adicionais = payload;
    },
    SET_BLACKLIST(state, payload) {
        state.cotacao.blacklist = payload;
    },
    SET_COTACAO_USUARIO(state, payload) {
        state.usuario = payload;
    },
    SET_USUARIO(state, payload) {
        state.auth.logged = payload.id || false;
        state.auth.usuario = payload;
    },
    SET_DATA_TABLE_MODAL(state, payload) {
        state.data_table.showModal = payload;
    },
    SET_DATA_TABLE_MODAL_ITEM(state, payload) {
        state.data_table.item = payload;
    },
    SET_COTACAO_CILINDRADA(state, payload) {
        state.cotacao.cilindrada = payload;
    },
    SET_PLANOS(state, payload) {
        state.planos = payload;
    }
};
